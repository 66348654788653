import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import clsx from 'clsx';
import { getCountryForTimezone } from 'countries-and-timezones';
import platform from 'platform';
import { StyledChatsWrapper } from '../../components/styled';
import LiveChatContainer from '../LiveChat/LiveChatContainer';
import { useWidget } from '../../WidgetProvider';
import { useLiveChat } from '../../LiveChatProvider';
import ChooseChannels from '../ChooseChannels';
import PreChatForm from '../PreChatForm';
import { useSendEventWebsocket, useWidgetReceivingWebsocket } from '../../hooks/useWidgetWebsocket';
import { useWidgetHeight } from '../../hooks/useWidgetHeight';
import { ChatEvent, IChatEventBrief, ScreenType } from '../../types';
import KnowledgeBaseList from '../KnowledgeBaseList.tsx/KnowledgeBaseList';
import KnowledgeBaseArticle from '../KnowledgeBaseArticle.tsx/KnowledgeBaseArticle';
import { getIsPresentKnowledgeBase } from '../../utils/utils';
import { CUSTOM_EVENT_SEND_MESSAGE, CUSTOM_EVENT_SEND_SYSTEM_MESSAGE } from '../../constants';
import { isMessageSendedToday } from '../../systemMessages/helpers';
import { SYSTEM_MESSAGES } from '../../enums';
import { getDeviceType } from '../../utils/metadata';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface IProps {
	className?: string,
}

const WidgetButtonForms = (props: IProps) => {
	const { className } = props;

	const {
		license,
		contactUid,
		widgetOpened,
		setWidgetOpened,
		knowledgeBaseResponse,
		chatDetails,
		isNonWorkingTime,
		widgetSettings,
		isPreChatFormFilled,
		chatStartedOrigin,
		setLiveChatOpenedAfterFirstAutoReply,
	} = useWidget();
	const {
		isAllMessagesSeen,
		currentHistoryCount,
		history,
		onSetIsAllMessagesSeen,
		setChatStarted,
		chatStatus,
	} = useLiveChat();

	const isLiveChatActive = widgetSettings?.channels?.live_chat?.status === 'active';

	const isMounted = useRef(false);

	const [screens, setScreens] = useState<ScreenType[]>([]);
	const [currentScreen, setCurrentScreen] = useState<ScreenType>('');

	useWidgetReceivingWebsocket();

	const [isChannelButtonClicked, setIsChannelButtonClicked] = useState('');
	const [timeoutIdWidgetOpened, setTimeoutIdWidgetOpened] = useState<null | NodeJS.Timeout>(null);

	useEffect(() => {
		if (
			isChannelButtonClicked === 'messenger'
      || (currentScreen === 'preChatForm' && isChannelButtonClicked === 'live_chat')
		) {
			setIsChannelButtonClicked('');
		}
	}, [isChannelButtonClicked]);

	const {
		is_hide_branding,
		button_placement,
		is_form_displayed,
		form_fields,
		privacy_policy,
	} = widgetSettings.settings;

	const { live_chat_delayed } = widgetSettings.auto_reply_settings || {};
	const { first_message } = live_chat_delayed;

	const isFirstVisitToday = isMessageSendedToday(SYSTEM_MESSAGES['live_chat_delayed.first_message'], chatStartedOrigin);

	useEffect(() => {
		if (chatDetails) {
			isMounted.current = false;
			setCurrentScreen(history?.length ? 'liveChat' : 'preChatForm');
		}
	}, [chatDetails]);

	const {
		sendEventToServer,
	} = useSendEventWebsocket(() => null, () => null);

	const handleMessageCustomEvent = (event: MessageEvent) => {
		try {
			const messageLocal = event.data.message;
			const emailLocal = event.data.email;
			const nameLocal = event.data.name;
			const originLocal = event.data.origin;
			const businessCommentId = event.data?.businessCommentId;
			const businessImages = event.data?.businessImages;

			const mainData: IChatEventBrief = {
				type: 'contact_message' as ChatEvent,
				data: {
					text: messageLocal,
					is_business_comment: true,
					business_comment_id: businessCommentId,
					business_images: businessImages,
				},
			};

			if (chatStatus !== 'open') {
				setChatStarted(true);

				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

				sendEventToServer({
					type: 'chat_started',
					data: {
						is_business_comment: true,
						origin: originLocal || '',
						contact: {
							email: emailLocal || '',
							name: nameLocal || '',
						},
						metadata: {
							timezone,
							country: getCountryForTimezone(timezone)?.id,
							browser: `${platform.name} ${platform.version}`,
							os: platform.os?.toString() || '',
							device: getDeviceType(),
							browserLanguage: window.navigator.language?.split('-')?.[0],
							cameFrom: 'live_chat',
						},
					},
				});
			} else {
				sendEventToServer({
					type: 'contact_updated',
					data: {
						contact: {
							email: emailLocal || '',
							name: nameLocal || '',
						},
					},
				});
			}

			setTimeout(() => {
				sendEventToServer(mainData);
			}, 1000);
		} catch (e) {
			// empty error
		}
	};

	const handleMessage = (event: MessageEvent) => {
		if (
			event?.data?.type
			&& [CUSTOM_EVENT_SEND_SYSTEM_MESSAGE, CUSTOM_EVENT_SEND_MESSAGE].includes(event.data.type)
			&& screens.includes('liveChat')
			&& !screens.includes('preChatForm')
		) {
			if (!widgetOpened) {
				setWidgetOpened(true);
			}
			setCurrentScreen('liveChat');
		}

		if (event?.data?.type === CUSTOM_EVENT_SEND_MESSAGE) {
			handleMessageCustomEvent(event);
		}
	};

	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => window.removeEventListener('message', handleMessage);
	}, [
		screens.length,
		currentScreen,
		widgetOpened,
		chatStatus,
	]);

	useEffect(() => {
		if (chatDetails && !isMounted.current && !widgetOpened) {
			setWidgetOpened(true);
		}
	}, [chatDetails]);

	const isOnlyLiveChatActive = (isLiveChatActive
		&& !Object.keys(widgetSettings.channels).some((channel) => channel !== 'callback'
			&& channel !== 'live_chat'
			// @ts-ignore
			&& widgetSettings.channels[channel].status === 'active' && widgetSettings.channels[channel].is_visible));

	const isPreChatDisplay = is_form_displayed && currentHistoryCount === 0;

	const openLiveChatWithDelay = () => {
		if (!isFirstVisitToday && first_message.status === 'active' && isLiveChatActive && !isNonWorkingTime) {
			const timeoutId = setTimeout(() => {
				setCurrentScreen('liveChat');
				setLiveChatOpenedAfterFirstAutoReply(true);
				setWidgetOpened(true, 'widget');
			}, first_message.delay * 1000);
			setTimeoutIdWidgetOpened(timeoutId);
		}
	};

	useEffect(() => {
		if (!is_form_displayed) {
			openLiveChatWithDelay();
		}
	}, []);

	useEffect(() => {
		if (chatStatus === 'closed') {
			openLiveChatWithDelay();
		}
		if ((chatStatus === 'new' || chatStatus === 'open') && timeoutIdWidgetOpened) {
			clearTimeout(timeoutIdWidgetOpened);
		}
	}, [chatStatus]);

	useEffect(() => {
		if (isNonWorkingTime && timeoutIdWidgetOpened) {
			clearTimeout(timeoutIdWidgetOpened);
		}
	}, [isNonWorkingTime]);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const parentWidth = urlParams.get('width');
		if (parentWidth && parseInt(parentWidth, 10) < 481) {
			const textareas = document.getElementsByTagName('textarea');
			const inputs = document.querySelectorAll('input[type="text"]');

			for (let i = 0; i < textareas.length; i += 1) {
				textareas[i].style.fontSize = '16px';
				textareas[i].style.lineHeight = '20px';
			}

			for (let i = 0; i < inputs.length; i += 1) {
				// @ts-ignore
				inputs[i].style.fontSize = '16px';
				// @ts-ignore
				inputs[i].style.lineHeight = '20px';
			}
		}
	}, [widgetOpened]);

	const getOfflineText = () => (
		isNonWorkingTime ? 'teamIsOfflineLiveChatOnlyText' : ''
	);

	useWidgetHeight({
		currentScreen,
		isOffline: !!getOfflineText(),
		screens,
		widgetSettings,
		formFields: form_fields,
	});

	useEffect(() => {
		if (currentHistoryCount === null) {
			return;
		}

		const screensLocal: ScreenType[] = [];

		if (widgetSettings?.channels?.live_chat) {
			screensLocal.push('liveChat');
		}

		if (isPreChatDisplay && !isPreChatFormFilled) {
			screensLocal.unshift('preChatForm');
		}

		if (!isOnlyLiveChatActive) {
			screensLocal.unshift('chooseChannels');
		}

		if (getIsPresentKnowledgeBase(knowledgeBaseResponse)) {
			screensLocal.push('knowledgeBaseList');
			screensLocal.push('knowledgeBaseItem');
		}

		setScreens(screensLocal);

		if (!screensLocal.includes(currentScreen)) {
			setCurrentScreen(screensLocal[0] || '');
		}
	}, [
		currentHistoryCount,
		isOnlyLiveChatActive,
		isPreChatDisplay,
		isPreChatFormFilled,
		JSON.stringify(knowledgeBaseResponse),
	]);

	useEffect(() => {
		if (!isMounted.current && screens.length > 0 && !screens.includes(currentScreen)) {
			setCurrentScreen(screens[0]);
			isMounted.current = true;
		}
	}, [screens]);

	useEffect(() => {
		if (widgetOpened && !isAllMessagesSeen && currentScreen === 'liveChat') {
			onSetIsAllMessagesSeen(history, true);
		}
	}, [history, widgetOpened, onSetIsAllMessagesSeen, isAllMessagesSeen, currentScreen]);

	const goOneScreenBack = () => {
		const currentIndex = screens.indexOf(currentScreen);
		setCurrentScreen(screens[currentIndex - 1]);
	};

	const goOneScreenForward = () => {
		const currentIndex = screens.indexOf(currentScreen);
		setCurrentScreen(screens[currentIndex + 1]);
	};

	const showChooseChannels = !isOnlyLiveChatActive;

	const goOneScreenPreChatForm = screens[0] === 'preChatForm' || !screens.includes('preChatForm')
		? undefined
		: goOneScreenBack;

	return (
		<StyledChatsWrapper
			button_placement={button_placement}
			id="chatWrapper"
			className={clsx('minimised', className, 'chatWrapper', button_placement)}
		>
			{showChooseChannels && currentScreen === 'chooseChannels' ? (
				<ChooseChannels
					screens={screens}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					hideWidget={() => setWidgetOpened(false)}
					goOneScreenForward={goOneScreenForward}
					className="chooseChannels"
					channels={widgetSettings?.channels}
					is_hide_branding={is_hide_branding}
					offlineText={getOfflineText()}
					setIsChannelButtonClicked={setIsChannelButtonClicked}
				/>
			) : null}

			{currentScreen === 'preChatForm' ? (
				<PreChatForm
					screens={screens}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					hideWidget={() => setWidgetOpened(false)}
					goOneScreenForward={goOneScreenForward}
					goOneScreenBack={goOneScreenPreChatForm}
					className="preChatForm"
					is_hide_branding={is_hide_branding}
					fields={form_fields}
					privacy_policy={privacy_policy}
					offlineText={screens[0] === 'preChatForm' && getOfflineText()}
				/>
			) : null}

			{currentScreen === 'liveChat' ? (
				<LiveChatContainer
					offlineText={screens[0] === 'liveChat' && getOfflineText()}
					currentScreen={currentScreen}
					goOneScreenBack={screens[0] === 'liveChat' ? undefined : goOneScreenBack}
					setCurrentScreen={setCurrentScreen}
					license={license}
					contactUid={contactUid}
					className="liveChat"
					is_hide_branding={is_hide_branding}
				/>
			) : null}

			{currentScreen === 'knowledgeBaseList' ? (
				<KnowledgeBaseList
					screens={screens}
					currentScreen={currentScreen}
					className="knowledgeBaseList"
					is_hide_branding={is_hide_branding}
					setCurrentScreen={setCurrentScreen}
				/>
			) : null}

			{currentScreen === 'knowledgeBaseItem' ? (
				<KnowledgeBaseArticle
					screens={screens}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					goOneScreenBack={goOneScreenBack}
					className="knowledgeBaseItem"
					is_hide_branding={is_hide_branding}
				/>
			) : null}

		</StyledChatsWrapper>
	);
};

export default WidgetButtonForms;