import React, {
	useState,
	useRef,
	useEffect,
	useContext,
} from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import clsx from 'clsx';

import styled from 'styled-components';
import UploadedItem from './UploadedItem';
import { useSendEventWebsocket } from '../hooks/useWidgetWebsocket';
import useAutosizeTextArea from '../hooks/useAutosizeTextArea';
import Typography from './atomic/Typography';
import Input from './atomic/Input';
import ActionButtons from './ActionButtons';
import { useLiveChat } from '../LiveChatProvider';
import { useWidget } from '../WidgetProvider';
import {
	ChatEvent,
	IChatEventBrief,
	IFileUploadResult,
	ScreenType,
	TextAreaRef,
} from '../types';
import {
	CHECKBOX_HEIGHT,
	CHECKBOX_HEIGHT_EN,
	CONTACT_FORM_FOOTER_HEIGHT,
	FILE_SIZE_LIMIT,
	FILE_TYPES,
	PAGINATION_TABS_HEIGHT,
} from '../constants';
import { getIsPresentKnowledgeBase } from '../utils/utils';
import { LangContext } from '../LangProvider';
import { uploadFile } from '../api';
import { getDeviceType } from '../utils/metadata';

interface IProps {
	currentScreen: ScreenType,
	handleSendSuccess?: () => void,
	getIsFormValid?: (fieldToCheck?: string, value?: string) => boolean,
	messageText: string,
	operatorName?: string,
	onClearReply?: () => void,
	replyData?: {
		replyId: number | undefined,
		replyMsg: string,
		replyType: ChatEvent | null,
		replyFile: IFileUploadResult | null,
	}
}

const StyledWrapEmoji = styled.div<{
	height: number
}>`
  position: absolute;
  bottom: 75px;
  left: 12px;

  em-emoji-picker {
    max-height: ${({ height }) => height}px;
  }
`;

const Textarea = (props: IProps) => {
	const {
		currentScreen,
		handleSendSuccess,
		getIsFormValid,
		messageText,
		operatorName,
		onClearReply,
		replyData,
	} = props;

	const {
		replyId, replyMsg = '', replyType = null, replyFile,
	} = replyData ?? {};

	const textAreaRef = useRef<TextAreaRef>(null);
	const getTranslate = useContext(LangContext);

	const {
		widgetOpened,
		license,
		contactUid,
		widgetSettings,
		knowledgeBaseResponse,
	} = useWidget();

	const { language } = widgetSettings;
	const { privacy_policy, is_hide_branding } = widgetSettings?.settings || {};

	const widgetHeight: number = (window.document.querySelector('#chatWrapper')
		?.getBoundingClientRect().height || 0);

	const heightBranding = is_hide_branding ? 5 : CONTACT_FORM_FOOTER_HEIGHT;

	const checkboxHeight = language === 'en' ? CHECKBOX_HEIGHT_EN : CHECKBOX_HEIGHT;

	const heightPrivacyPolicyIsRequired = privacy_policy?.is_required
		? checkboxHeight
		: 0;

	const isPresentKnowledgeBase = getIsPresentKnowledgeBase(knowledgeBaseResponse);

	const heightFromPaginationTabs = isPresentKnowledgeBase ? PAGINATION_TABS_HEIGHT : 0;

	const heightTextArea = 100;

	const bottomMargin = heightTextArea
		+ heightFromPaginationTabs
		+ heightBranding
		+ heightPrivacyPolicyIsRequired;

	const heightEmoji = widgetHeight - bottomMargin;

	const {
		history,
		chatStatus,
		chatStarted,
		setChatStarted,
	} = useLiveChat();

	const [message, setMessage] = useState('');
	const [files, setFiles] = useState<IFileUploadResult[]>([]);
	const [isTyping, setIsTyping] = useState(false);

	const [openedEmoji, setOpenedEmoji] = useState(false);

	const [errorText, setErrorText] = useState('');

	const isTypingTimeout = useRef<number | undefined>();

	useEffect(() => {
		if (messageText) {
			setMessage(messageText);
		}
	}, [messageText]);

	useAutosizeTextArea(textAreaRef.current, message, 126, currentScreen === 'preChatForm' || messageText);

	useEffect(() => {
		if (
			textAreaRef.current && getDeviceType() !== 'phone'
		) {
			textAreaRef.current?.focus();
		}
	}, [currentScreen, widgetOpened]);

	const constructMessageData = (type = 'contact_message') => {
		const mainData: IChatEventBrief = {
			type: type as ChatEvent,
			data: {},
		};

		if (type === 'contact_message') {
			if (message) {
				mainData.data.text = message;
			}
			if (files.length) {
				mainData.data.files = files;
			}
			if (replyId && replyType && (replyMsg || replyFile)) {
				mainData.data.reply_message_id = replyId;
				mainData.data.reply_message_text = replyMsg;
				mainData.data.reply_message_type = replyType;

				if (replyFile) {
					mainData.data.reply_message_file_data = [replyFile];
				}
			}
		}

		return mainData;
	};

	const removeFileError = () => {
		setErrorText('');
	};

	const handlePaste = (event: React.ClipboardEvent) => {
		try {
			const clipboardData: React.ClipboardEvent['clipboardData'] = event.clipboardData
				// @ts-ignore
				|| event.originalEvent.clipboardData;

			const types = [
				...Object.keys(FILE_TYPES.images),
				...Object.keys(FILE_TYPES.files),
			];

			const imageItems = Array.from(clipboardData.items)
				.filter((item) => types.includes(item.type));

			if (imageItems.length === 0) {
				return;
			}

			const files: File[] = [];

			imageItems.forEach((imageItem) => {
				const blob = imageItem.getAsFile();

				if (blob === null) {
					return;
				}

				event.preventDefault();

				if (blob.size > FILE_SIZE_LIMIT) {
					return;
				}

				const file = new File([blob], blob.name || 'screenshot.jpg');
				// @ts-ignore
				file.preview = URL.createObjectURL(blob);

				files.push(file);
			});

			if (files.length > 1) {
				files.length = 1;
			}

			if (files.length > 0) {
				uploadFile(license, contactUid, files[0])
					.then((res) => {
						setFiles((prev) => [...prev, res]);
					});
			}
		} catch (error) {
			event.preventDefault();
		}
	};

	const onSend = () => {
		if ((message && message.trim()) || files.length) {
			const data = constructMessageData();

			if (message === 'error') {
				// @ts-ignore
				// eslint-disable-next-line no-use-before-define
				sendMessage(data);
			} else {
				// eslint-disable-next-line no-use-before-define
				sendEventToServer(data);
			}
		}

		removeFileError();
	};

	const onMessageSentSuccess = (eventInProgress: string, callback: () => void) => {
		if (eventInProgress === 'contact_message' && (message || files)) {
			setMessage('');
			setFiles([]);
			callback();
			if (handleSendSuccess) {
				handleSendSuccess();
			}
			textAreaRef.current?.focus();
			setIsTyping(false);
			onClearReply?.();
		}
	};

	const {
		sendEventToServer,
		sendMessage,
		eventSentStatus,
		eventInProgress,
		onSendEvent,
		setEventSentStatus,
		setEventInProgress,
	} = useSendEventWebsocket(onSend, onMessageSentSuccess);

	useEffect(() => {
		if (!chatStarted && history.length && chatStatus && chatStatus !== 'closed') {
			setChatStarted(true);
		}
	}, [eventSentStatus, chatStatus, chatStarted, history.length, setChatStarted]);

	const inProgress = eventSentStatus === 'inProgress'
    || eventInProgress === 'chat_started'
    || eventInProgress === 'firstUpload'
    || eventSentStatus === 'error';

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(e.currentTarget.value);
		removeFileError();
	};

	const onSendClick = () => {
		let isFormValid = true;

		if (getIsFormValid) {
			isFormValid = getIsFormValid();
		}

		if (inProgress && eventInProgress !== 'chat_started') {
			return;
		}

		if ((message && message.trim()) || files.length) {
			if (!isFormValid) {
				return;
			}

			onSendEvent('contact_message')();
		}
	};

	useEffect(() => {
		if (eventInProgress === 'chat_started' && !eventSentStatus) {
			onSendClick();
		}
	}, [
		license,
		contactUid,
		eventInProgress,
		eventSentStatus,
		onSendEvent,
		setEventInProgress,
		setEventSentStatus,
	]);

	useEffect(() => {
		if (eventInProgress === 'firstUpload' && !eventSentStatus) {
			onSendClick();
		}
	}, [eventInProgress, eventSentStatus, onSendClick]);

	useEffect(() => {
		if (eventSentStatus === 'duplicated') {
			setMessage('');
		}
	}, [eventSentStatus]);

	useEffect(() => {
		if (!isTyping && chatStatus === 'open') {
			sendEventToServer({ type: 'contact_not_typing', contact_id: contactUid });
			clearTimeout(isTypingTimeout.current);
		}

		if (isTyping) {
			sendEventToServer({ type: 'contact_typing', contact_id: contactUid });
		}
	}, [isTyping, contactUid, chatStatus]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (inProgress) {
			return;
		}

		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			onSendClick();
		} else {
			if (!isTyping && chatStatus === 'open') {
				setIsTyping(true);
			}

			if (isTypingTimeout.current) {
				clearTimeout(isTypingTimeout.current);
			}

			if (chatStatus === 'open') {
				// @ts-ignore
				isTypingTimeout.current = setTimeout(() => {
					setIsTyping(false);
				}, 5000);
			}
		}
	};

	const handleBlur = () => {
		if (isTyping) {
			setIsTyping(false);
		}
	};

	const onEmojiClick = (e: React.FormEvent) => {
		e?.stopPropagation();

		removeFileError();
		setOpenedEmoji((prev) => !prev);
	};

	const deleteFile = () => {
		setErrorText('');
		setFiles((prev) => prev.filter((v, i) => i !== 0));
	};

	const onEmojiSelect = (emoji: { native: string }) => {
		const cursorPlace = textAreaRef.current.selectionStart;
		const messageStart = message.slice(0, cursorPlace);
		const messageEnd = message.slice(cursorPlace);
		const text = messageStart + emoji.native + messageEnd;
		setMessage(text);
		setOpenedEmoji((prev) => !prev);
		textAreaRef.current.focus();
	};

	const tooManyFilesTranslated = getTranslate('too-many-files');
	const enterYourMessageTranslate = getTranslate('enterYourMessage');

	useEffect(() => {
		if (files.length > 1) {
			setErrorText(tooManyFilesTranslated);

			setFiles((prev) => prev.filter((v, i) => i === 0));
		}
	}, [files.length]);

	return (
		<>
			<Input
				id="liveChatTextarea"
				disabled={inProgress}
				// @ts-ignore
				error={eventSentStatus === 'error' && !!(message || files?.length)}
				rows={1}
				multiline
				fullWidth
				placeholder={enterYourMessageTranslate}
				insideButtons={(
					<ActionButtons
						inProgress={inProgress}
						onEmojiClick={onEmojiClick}
						onSend={onSendClick}
						setFiles={setFiles}
						setErrorText={setErrorText}
					/>
				)}
				operatorName={operatorName}
				replyData={{
					replyId, replyMsg, replyType, replyFile,
				}}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}
				onPaste={handlePaste}
				onClearReply={onClearReply}
				value={message}
				textAreaRef={textAreaRef}
				className={clsx(files.length && 'hasItem')}
			>
				{errorText ? (
					<Typography
						text={errorText}
						variant="input"
						textAlign="center"
						className="errorText"
					/>
				) : null}
			</Input>
			<UploadedItem
				file={files[0]}
				deleteFile={deleteFile}
			/>

			<div id="emoji-picker">
				{openedEmoji && (
					<StyledWrapEmoji height={heightEmoji}>
						<Picker
							data={data}
							onEmojiSelect={onEmojiSelect}
							onClickOutside={onEmojiClick}
							perLine={8}
						/>
					</StyledWrapEmoji>
				)}
			</div>
		</>
	);
};

export default Textarea;