import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import Cookies from 'universal-cookie';

import { nanoid } from 'nanoid';
import { getChatHistory, getOperatorOnlineStatus } from './api';
import { CHAT_EVENTS_TO_SHOW_IN_WIDGET, CUSTOM_EVENT_SEND_SYSTEM_MESSAGE, EnumLocalStorageKeys } from './constants';
import { useWidget } from './WidgetProvider';
import { storageAvailable } from './utils';
import { ChatEvent, IChatEventBrief, ILiveChatContext } from './types';
import { SYSTEM_MESSAGES } from './enums';

interface IProps {
	children: React.ReactNode,
}

const LiveChatContext = createContext<ILiveChatContext | null>(null);

function getLastMessageIdFromLocalStorage(chatStartedOrigin: string) {
	if (storageAvailable()) {
		// fix cookies
		const lastMessageIdSeenOld = localStorage.getItem('lastMessageIdSeen');
		if (lastMessageIdSeenOld) {
			localStorage.setItem(`lastMessageIdSeen-${chatStartedOrigin}`, lastMessageIdSeenOld);
			localStorage.removeItem('lastMessageIdSeen');
		}

		const lastMessageIdFromLS = lastMessageIdSeenOld || localStorage.getItem(`lastMessageIdSeen-${chatStartedOrigin}`);

		if (lastMessageIdFromLS) {
			return lastMessageIdFromLS;
		}
	}
	return null;
}

function getSoundSettingFromLS(chatStartedOrigin: string) {
	if (storageAvailable()) {
		// fix cookies
		const cookies = new Cookies();
		const soundSettingOld = cookies.get('soundSetting');
		if (soundSettingOld) {
			localStorage.setItem(`soundSetting-${chatStartedOrigin}`, soundSettingOld);
			cookies.remove('soundSetting');
		}

		const soundSetting = soundSettingOld || localStorage.getItem(`soundSetting-${chatStartedOrigin}`);
		if (soundSetting === undefined) {
			localStorage.setItem(`soundSetting-${chatStartedOrigin}`, 'true');
		}

		return soundSetting !== 'false';
	}
	return false;
}

const LiveChatProvider = (props: IProps) => {
	const { children } = props;

	const { contactUid, license, chatStartedOrigin } = useWidget();

	const [history, setHistory] = useState<IChatEventBrief[]>([]);
	const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0);
	const [currentHistoryCount, setCurrentHistoryCount] = useState<number>(0);
	const [chatStarted, setChatStarted] = useState(false);
	const [chatId, setChatId] = useState<number>(0);
	const [chatStatus, setChatStatus] = useState('');
	const [operatorName, setOperatorName] = useState('');
	const [operatorPic, setOperatorPic] = useState('');
	const [operatorOnline, setOperatorOnline] = useState(false);
	const [eventJustSent, setEventJustSent] = useState('');
	const [eventJustReceived, setEventJustReceived] = useState('');
	const [isSoundOn, setIsSoundOn] = useState(getSoundSettingFromLS(chatStartedOrigin));
	const [isAllMessagesSeen, setIsAllMessagesSeen] = useState(true);
	const [systemMessages, setSystemMessages] = useState<IChatEventBrief[] | []>([]);
	const [latestHistoryEventType, setLatestHistoryEventType] = useState<string>('');

	const preChatFormValues = useRef<Record<string, string> | null>(null);

	const isInstantAutoMessageSent = useRef<boolean>(false);

	const onSetIsAllMessagesSeen = useCallback(
		(history: IChatEventBrief[], isAllSeen: boolean) => {
			const historyWithMessagesFromOperator = history.filter((event) => event.type === 'operator_message');

			if (!historyWithMessagesFromOperator?.length) {
				setIsAllMessagesSeen(true);
			} else {
				const lastMessageFromOperator = historyWithMessagesFromOperator[
					historyWithMessagesFromOperator.length - 1
				];

				const lastMessageIdFromLS = getLastMessageIdFromLocalStorage(chatStartedOrigin);
				const isMessagesSeen = isAllSeen
					|| lastMessageFromOperator?.id === +(lastMessageIdFromLS || 0);

				setIsAllMessagesSeen(isMessagesSeen);

				if (isMessagesSeen && lastMessageFromOperator?.id && storageAvailable()) {
					localStorage.setItem(`lastMessageIdSeen-${chatStartedOrigin}`, lastMessageFromOperator.id.toString());
				}
			}
		},
		[chatStartedOrigin],
	);

	const onToggleSounds = () => {
		setIsSoundOn((prev) => {
			if (storageAvailable()) {
				localStorage.setItem(`soundSetting-${chatStartedOrigin}`, JSON.stringify(!prev));
			}

			return !prev;
		});
	};

	const isChatClosed = !!history.length && chatStatus === 'closed';
	const isChatClosedTheLatestEvent = isChatClosed && history[history.length - 1]?.type === 'chat_closed';
	const isChatReopenedTheLatestEvent = !isChatClosed && history[history.length - 1]?.type === 'chat_reopened';
	const isChatNew = !history.length || chatStatus === 'new';

	const setHistoryEvent = (event: IChatEventBrief) => {
		if (event.operator_name) {
			setOperatorName(event.operator_name);
		}

		if (event.type === 'operator_message' || event.type === 'contact_message') {
			setLatestHistoryEventType(event.type);
		}

		if (event.type === 'operator_joined') {
			setChatStatus('open');
		} else if (event.type === 'chat_closed' || event.type === 'operator_rated') {
			setChatStatus('closed');
			localStorage.removeItem(`${SYSTEM_MESSAGES['all_channels_instant.first_message']}-${chatStartedOrigin}`);
			localStorage.removeItem(`${SYSTEM_MESSAGES['all_channels_instant.second_message']}-${chatStartedOrigin}`);
		}

		if (!chatId && event.chat_id) {
			setChatId(event.chat_id);
		}

		setHistory((prev) => {
			const newEvent: IChatEventBrief = event.at ? event : { ...event, at: new Date() };

			if (prev[prev.length - 1]?.type === 'operator_typing') {
				return [
					...prev.slice(0, prev.length - 1),
					newEvent,
					prev[prev.length - 1],
				];
			}
			return [...prev, newEvent];
		});

		setCurrentHistoryCount((prev) => (prev || 0) + 1);
	};

	const handleCustomEventSendSystemMessage = (event: MessageEvent) => {
		try {
			const messageLocal = event.data.message;

			const newMessage: IChatEventBrief = {
				// @ts-ignore
				id: nanoid(),
				type: 'system_message' as ChatEvent,
				// @ts-ignore
				data: { text: messageLocal },
			};

			setHistory((prev) => ([
				...prev,
				newMessage,
			]));

			setCurrentHistoryCount((prev) => prev + 1);

			const savedJson = localStorage.getItem(
				EnumLocalStorageKeys.systemMessages + chatStartedOrigin,
			);

			const saved: IChatEventBrief[] = savedJson ? JSON.parse(savedJson) : [];

			localStorage.setItem(EnumLocalStorageKeys.systemMessages + chatStartedOrigin, JSON.stringify([
				...saved,
				newMessage,
			]));
		} catch (e) {
			// empty error
		}
	};

	useEffect(() => {
		if (license && contactUid) {
			getChatHistory(license, contactUid)
				.then((res) => {
					if (res && res.items) {
						const itemsFiltered = res.items.filter(
							(item) => item.type && CHAT_EVENTS_TO_SHOW_IN_WIDGET
								.includes(item.type),
						);

						setCurrentHistoryCount(res.items.length || 0);
						const newHistory = itemsFiltered.reverse();
						setHistory((prev) => [...prev, ...newHistory]);
						setTotalHistoryCount(res.total);
						onSetIsAllMessagesSeen(newHistory, false);
					} else {
						setCurrentHistoryCount(0);
					}

					const savedJson = localStorage.getItem(
						EnumLocalStorageKeys.systemMessages + chatStartedOrigin,
					);

					const saved: IChatEventBrief[] = savedJson ? JSON.parse(savedJson) : [];

					setHistory((prev) => ([
						...prev,
						...saved,
					]));

					setCurrentHistoryCount((prev) => prev + saved.length);
				})
				.catch(() => {
					setCurrentHistoryCount(0);
				});

			getOperatorOnlineStatus(license, contactUid).then((res) => {
				if (res?.length) {
					setOperatorOnline(true);
				}
			});
		}
	}, [contactUid, license]);

	useEffect(() => {
		if (!chatStatus && history.length) {
			setChatStatus(history[0]?.chat_status || '');
		}
	}, [chatStatus, history.length]);

	useEffect(() => {
		if (!operatorName && history.length) {
			const operatorName = [...history].reverse().find((item) => item.operator_name)?.operator_name;
			setOperatorName(operatorName || '');
		}
	}, [history.length, operatorName]);

	useEffect(() => {
		if (!operatorPic && history.length) {
			const operatorPic = [...history].reverse().find((item) => item.operator_pic)?.operator_pic;
			setOperatorPic(operatorPic || '');
		}
	}, [history.length, operatorPic]);

	useEffect(() => {
		if (!chatId && history.length) {
			setChatId(history[0].chat_id || 0);
		}
	}, [chatId, history.length]);

	useEffect(() => {
		if (isChatClosedTheLatestEvent) {
			setHistory((prev) => [...prev, { type: ChatEvent.show_rating_options, data: {} }]);
		}

		if (isChatNew || isChatReopenedTheLatestEvent) {
			setHistory((prev) => prev.filter((item) => item.type !== 'show_rating_options'));
		}
	}, [isChatClosedTheLatestEvent, isChatNew, isChatReopenedTheLatestEvent]);

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			if (event?.data?.type === CUSTOM_EVENT_SEND_SYSTEM_MESSAGE) {
				handleCustomEventSendSystemMessage(event);
			}
		};

		window.addEventListener('message', handleMessage);

		return () => window.removeEventListener('message', handleMessage);
	}, []);

	return (
		<LiveChatContext.Provider
			/* eslint-disable-next-line
			react/jsx-no-constructed-context-values,sonarjs/jsx-no-constructed-context-values
			 */
			value={{
				history,
				totalHistoryCount,
				chatStarted,
				onSetIsAllMessagesSeen,
				preChatFormValues,
				isInstantAutoMessageSent,
				operatorName,
				operatorOnline,
				chatStatus,
				currentHistoryCount,
				eventJustSent,
				eventJustReceived,
				chatId,
				isSoundOn,
				isAllMessagesSeen,
				setOperatorOnline,
				setChatStarted,
				setChatStatus,
				setOperatorName,
				setOperatorPic,
				setCurrentHistoryCount,
				setEventJustSent,
				setEventJustReceived,
				onToggleSounds,
				setHistory,
				setHistoryEvent,
				setIsAllMessagesSeen,
				systemMessages,
				setSystemMessages,
				latestHistoryEventType,
			}}
		>
			{children}
		</LiveChatContext.Provider>
	);
};

export const useLiveChat = () => {
	const context = useContext(LiveChatContext);

	if (!context) {
		throw new Error('useLiveChatContext must be within LiveChatProvider');
	}

	return context;
};

export default LiveChatProvider;